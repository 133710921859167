<template>
  <div>
    <template >
        <vx-card class="verif" v-bind:style="back()">
            <h1 align="center"> Page de vérification de l'integrité du document </h1>
              <br/>
              <vs-divider/>
              <span>
                Notice : <br/>
                  - Si le fichier à été modifié, les clés de signature ne correspondront pas et s’affichera en ROUGE et ne divulguera pas la clé.
                  <br/>
                  - Si le fichier est identique, les clés de signature correspondront et s’affichera en VERT en laissant apparaitre la clé.
                  <br/>
                  L’algorithme de Hash est calculé par SHA 256.

              </span>
              <vs-divider/>
              <br/>

              
              <br/>
              <vs-row>
                <vs-col vs-xs="12" vs-lg="6">
                  <h4 align="center"> Inserer le fichier de clotûre format .zip </h4>
                  <br/>
                  <div align="center">
                      
                    <input  type="file" @load="this.same" @change="getSign" accept=".zip"/>
                  </div>
                </vs-col>
                <template v-if="this.same==true">
                  <vs-col vs-xs="12" vs-lg="6">
                    <h4 align="center"> Clé  </h4>
                    <p> Signature attendue :</p>
                    <p style="font-size:13px;"> <br/> {{ this.signa }} </p>
                    <br/>
                    <p > Signature trouvée : </p>
                      <p style="font-size:13px;"> <br/> {{ this.csvR }} </p>
                  </vs-col>
                </template>
                <template v-else-if="this.same==false">
                  <vs-col vs-xs="12" vs-lg="6">
                    <h4 align="center"> Clé  </h4>
                    <p > La clé calculée ne correspond pas à celle attendu. </p>
                     <p> Signature attendu :</p>
                    <p style="font-size:13px;"> <br/> {{ this.signa }} </p>
                    <br/>
                  </vs-col>
                </template>
              
              </vs-row>
            <template v-if="this.format">
              <p style="color:red;"> Format incorrect. {{ this.formatType }}</p>
            </template>
        </vx-card>
    </template>
    
    
            
        
  </div>
</template>

<script>
import XLSX from 'xlsx'
import crypt from 'crypto-js';
// import moment from 'moment';

export default {
  data(){
    return{
      same:'?',
      format:false,
      compress:false,
      sign:false,
      csvf:false,
      errZip:false,
      errSig:false,
      csvR:'',
      signa:'',
      res:'',
      formatType:'',
    }
  },
  watch:{
    same:function(){
      this.back()
    }
    
  },
  methods:{
    back(){
      if( this.same==true){
        return "background:green; color:white;"
      }
      else if ( this.same == false ){
        return " background:red; color:white;"
      }
      else {
        return "background:white; "
      }
    },
    // pour les fichiers indivduel : pas utilisé 
    getSignature(e){
      if ( this.compress==false){
        this.sign=true;
        let file = e.target.files[0];
        
        if( file.type=="text/plain"){
          let that = this;
          let reader = new FileReader()
          reader.addEventListener("load", () => {
              that.signa= new TextDecoder().decode(reader.result);
              //  console.log(" === ", that.signa, " ?? ", that.csvR)
                if ( that.signa == that.csvR){

                  that.same=true;
                }
                else{
                  that.same=false;
                }
              // console.log(" this.sign"  , this.sign)
          }, false);


          reader.readAsArrayBuffer(file)

         
        }
        else{
            this.formatType=e.target.files[0].type;
          this.format=true;
        }
      }
      else{
        this.errSig=true;
      }
     
    },
    // pour calculer les clés 
    async getSign(e){

          
          if (this.csvf==false && this.sign==false){
            this.compress=true;
            // console.log(" e : ", e.target.files)
           let f = e.target.files[0]
           
           //if ( f.type == "application/zip" || f.type == "application/x-zip-compressed"){

            var reader = new FileReader()
            
            reader.onload = (e)=>{
               
                 this.callback(e)
            }

            reader.readAsArrayBuffer(f)
        //    }
        //    else{
        //        this.formatType=e.target.files[0].type;
        //      this.format=true;
        //    }
          }
          else{
            this.errZip=true;
          }
        },
        callback(e){
                               
          

         
          var JSZip = require('jszip')
            var new_zip = new JSZip();
             
                var ree='';
               var  si='';
                var that = this;
           new_zip.loadAsync(e.target.result)
              .then(function(zip) {
                // console.log(" zip : ",zip)
                for (let i in zip.files){
                  
                  
                  let z = zip.files[i]
                 // console.log(" z : ", z);
                  

                  // car mac ajoute des fichiers lors de la compression
                 if (z.name.includes('MACOSX')==false){
                    

                   // if(z.name.includes('signature.txt')){
                    if(z.name=='signature.txt'){
                      // var binData     = z._data.compressedContent;
                        z.async('string').then(function(file){
                          
                           si = file
                              // console.log(" siGNATURE ", si , " re ", ree )
                          if(si == ree && ree!=''){
                            that.signa=si;
                            that.csvR=ree;
                            that.same = true;
                          
                          }    
                          else if ( ree!='' && si != ree){
                            that.signa=si;
                            that.same=false
                            
                          }   
                                    
                        })      
                    }
                    else if ( z.name.includes(".csv")){
                         
                      z.async('array').then(function(datae){
                        
                              var data = new Uint8Array(datae);
                             
                    
                              var workbook = XLSX.read(data, {type: 'array', cellDates: true });
                            
                              // console.log("wb ", workbook)

                              let sheetName = workbook.SheetNames[0]
                              /* DO SOMETHING WITH workbook HERE */

                              // console.log(workbook);
                              let worksheet = workbook.Sheets[sheetName];
                              
                              // console.log ( "wok ", worksheet)
                              
                              let n = XLSX.utils.sheet_to_json(worksheet);
                              // console.log(n);
                              let sha ="";
                              
                              for (let i in n){
                                  
                                  sha = crypt.MD5(sha + JSON.stringify(n[i])).toString()
                                  // if (sha=="5d4ed550c695515b70d4095864288861")
                                      // console.log(" -- 4 ", sha," : ",n[i])
                              }
                               that.res  =  crypt.SHA256(sha).toString()
                              ree = crypt.SHA256(sha).toString()
                              // console.log(" == " ,that.res )
                          if(si == ree && si!=''){
                            that.signa=si;
                            that.csvR=ree;
                            that.same = true;
                          
                          }    
                          else if ( si!='' && si != ree){
                            // console.log(" clé ", si)
                            that.signa=si;
                            that.same=false;
                            
                          }  
                          

                     })
                    }
                    else{
                      that.signa=si;
                      that.same=false;
                    }
                  }
                  
                }
                
                 
              })
              
              
           
        },
       // pour les fichiers individuels: pas utilisé 
        getKey(e){
          if ( this.compress==false){
            let f = e.target.files[0];
            this.csvf=true;
            if ( f.type=="text/csv"){
                  let reader = new FileReader();
                  let that = this;
                  reader.onload = function(e) {
                  var data = new Uint8Array(e.target.result);
                  
                  var workbook = XLSX.read(data, {type: 'array', cellDates: true });
                
                  // console.log("wb ", workbook)

                  let sheetName = workbook.SheetNames[0]
                  /* DO SOMETHING WITH workbook HERE */
                  // console.log(workbook);
                  let worksheet = workbook.Sheets[sheetName];
                  
                  // console.log ( "wok ", worksheet)
                  
                  let n = XLSX.utils.sheet_to_json(worksheet);
                  // console.log(n);
                  let sha ="";
                  for (let i in n){
                      // console.log(" st : ", sha)
                      // console.log ( "ni ", n[i])
                      // console.log(" sj" , JSON.stringify(n[i]))
                      sha = crypt.MD5(sha + JSON.stringify(n[i])).toString()
                      // console.log(" -- 1 sha", sha)

                  }
                  // console.log(" result : ", crypt.SHA256(sha).toString())
                  // this.result = crypt.SHA256(sha).toString()
                  that.csvR = crypt.SHA256(sha).toString();
                };
                reader.readAsArrayBuffer(f);
            }
            else{
              this.format=true
            }
          }
          else{
            this.errSig=true;
          }
           
        }
  },
  
}
</script>

<style>

</style>